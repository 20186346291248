<template>
  <v-container>
    <v-row v-if="!fetchingData">
      <v-col cols="12">
        <Patcher
          :itemValue="itemData.title"
          itemName="title"
          itemLabel="Titel"
          :hideLabel="false"
          :route="patchRoute"
          inputElement="input"
          inputType="text"
          selectOptions=""
          itemValueKey=""
          itemTextKey=""
          :previewValue="itemData.title"
          @emit="itemData.title = $event.value"
        ></Patcher>

        <Patcher
          :itemValue="itemData.isB2B"
          itemName="isB2B"
          itemLabel="B2B"
          :hideLabel="true"
          :route="patchRoute"
          inputElement="switch"
          inputType=""
          hint="Business to Business: Nur für Geschäftskunden"
          @emit="itemData.isB2B = $event.value"
        ></Patcher>
      </v-col>

      <v-col cols="8" md="8" sm="6">
        <v-card class="mb-2">
          <v-toolbar color="primary" dark dense>
            <v-toolbar-title>Kategorie-Produkte</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <draggable
              class="list-group"
              tag="ul"
              v-model="categoryProducts"
              v-bind="dragOptions"
              :move="onMove"
              @start="isDragging = true"
              @end="isDragging = false"
              handle=".draghandle"
              style="
                min-height: 100px;
                background-color: rgba(0, 0, 0, 0.01);
                border: 1px solid rgba(0, 0, 0, 0.02);
              "
            >
              <v-list-item
                v-for="(element, index) in categoryProducts"
                :key="index"
              >
                <v-list-item-title>
                  <v-icon left class="draghandle">mdi-menu</v-icon>
                  {{ element.title }}
                  <v-icon right color="error" @click="removeItem(index)"
                    >mdi-delete</v-icon
                  >
                </v-list-item-title>
              </v-list-item>
            </draggable>
          </v-card-text>
        </v-card>

        <!-- Button to safe -->
        <v-btn color="primary" @click="safeData()">Speichern</v-btn>
      </v-col>

      <v-col cols="4" md="4" sm="6">
        <v-card class="mb-2">
          <v-toolbar color="dark" dark dense>
            <v-toolbar-title>Verfügbare Produkte</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <draggable
              tag="ul"
              v-model="availableProducts"
              v-bind="dragOptions"
              :move="onMove"
              handle=".draghandle"
              style="
                min-height: 100px;
                background-color: rgba(0, 0, 0, 0.01);
                border: 1px solid rgba(0, 0, 0, 0.02);
              "
            >
              <template v-if="availableProducts.length > 0">
                <v-list-item
                  v-for="(element, index) in availableProducts"
                  :key="index"
                >
                  <v-list-item-title class="subtitle-2">
                    <v-icon left class="draghandle">mdi-menu</v-icon>
                    {{ element.title }}
                  </v-list-item-title>
                </v-list-item>
              </template>
            </draggable>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Patcher from "@/components/ui/Patcher.vue";
import apiRequest from "@/mixins/apiRequest";
import draggable from "vuedraggable";
export default {
  name: "ShopEditCategory",

  components: {
    Patcher,
    draggable,
  },

  props: ["itemData", "shopId"],

  data() {
    return {
      filePatcherKey: 0,
      patchRoute: `/addons/shop/${this.shopId}/categories/${this.itemData.id}`,
      fetchingData: false,
      categoryProducts: [],
      availableProducts: [],
      someProductInEdit: false,
      preloader: false,
      editable: true,
      isDragging: false,
      delayedDragging: false,
    };
  },

  async created() {
    this.fetchingData = true;

    this.categoryProducts = await this.getCategoryData();
    this.availableProducts = await this.getAvailableProducts();

    // remove all products from availableProducts which are already in categoryProducts
    this.categoryProducts.forEach((element) => {
      this.availableProducts = this.availableProducts.filter(
        (item) => item.id !== element.id
      );
    });

    this.fetchingData = false;
  },

  methods: {
    orderList() {
      this.categoryProducts = this.categoryProducts.sort((one, two) => {
        return one.order - two.order;
      });
    },
    onMove({ relatedContext, draggedContext }) {
      const relatedElement = relatedContext.element;
      const draggedElement = draggedContext.element;
      return (
        (!relatedElement || !relatedElement.fixed) && !draggedElement.fixed
      );
    },

    /* --- */

    async safeData() {
      // extract the ids of categoryProducts and save them to an comma separated string
      let ids = [];
      this.categoryProducts.forEach((element) => {
        ids.push(element.id);
      });
      ids = ids.join(",");

      const params = new URLSearchParams();
      params.append("itemIds", ids);

      this.preloader = true;
      await this.patchRequest(
        `/addons/shop/${this.shopId}/categories/${this.itemData.id}`,
        params
      );

      this.$root.snackbarGlobal.snackbar = true;
      this.$root.snackbarGlobal.snackbarText = "Die Datei wurde aktualisiert";
      this.$root.snackbarGlobal.snackbarColor = "green";
      this.$root.snackbarGlobal.snackbarTimeout = 5000;

      this.preloader = false;
    },

    async getCategoryData() {
      // get data of the category
      this.preloader = true;
      let response = await this.getRequest(
        `/addons/shop/${this.shopId}/categories/${this.itemData.id}?include=items`
      );
      this.preloader = false;
      return response.data.data.included.items;
    },

    async getAvailableProducts() {
      // get all available products of that shop (not global)
      this.preloader = true;
      let response = await this.getRequest(
        `/addons/shop/${this.shopId}/products`
      );
      this.preloader = false;
      return response.data.data;
    },

    addElement: async function (evt) {
      this.someProductInEdit = true; /* set this to avoid other actions while waiting of response */
      let newIndex = evt.newIndex; // the index of the added array
      console.log("addElement +++++");
      console.log("newIndex: " + newIndex);
      console.log(
        `MAKE AN API REQUEST TO CREATE A NEW SLICE WITH NEWINDEX (${newIndex})`
      );
      console.log(`AFTER THAT REPLACE THE PLACEHOLDER WITH THE REAL DATA.`);
      console.log("+++++");
    },

    changeElement: async function (evt) {
      this.someProductInEdit = true; /* set this to avoid other actions while waiting of response */
      let oldIndex = evt.oldIndex; // the index of the added array
      let newIndex = evt.newIndex; // the index of the added array
      console.log("changeElement +++++");
      console.log("oldIndex: " + oldIndex);
      console.log("newIndex: " + newIndex);
      console.log(`MAKE AN API REQUEST TO CHANGE THE ORDER OF THE SLICES`);
      console.log("+++++");
    },

    removeItem(index) {
      this.categoryProducts.splice(index, 1);
    },
  },

  computed: {
    dragOptions() {
      return {
        animation: 0,
        group: "description",
        disabled: !this.editable,
        ghostClass: "ghost",
      };
    },
    listString() {
      return JSON.stringify(this.categoryProducts, null, 2);
    },
    list2String() {
      return JSON.stringify(this.availableProducts, null, 2);
    },
  },
  watch: {
    isDragging(newValue) {
      if (newValue) {
        this.delayedDragging = true;
        return;
      }
      this.$nextTick(() => {
        this.delayedDragging = false;
      });
    },
  },

  mixins: [apiRequest],
};
</script>
