<template>
  <div>
    <v-row>
      <v-col cols="12">
        <v-data-table
          v-model="selected"
          :headers="headers"
          :items="items"
          item-key="uuid"
          selectable-key="uuid"
          show-select
          class="elevation-1"
        >
        </v-data-table>
      </v-col>
      <v-col cols="12">
        <v-btn
          color="primary"
          class="ma-2"
          @click="importSelected()"
          :disabled="selected.length < 1 ? true : false"
        >
          <v-icon>mdi-plus</v-icon>
          <span>{{ buttonText() }}</span>
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import apiRequest from "@/mixins/apiRequest";
export default {
  name: "ProductTemplatesList",

  props: ["shopId", "companyId"],

  data() {
    return {
      selected: this.selection ?? [],
      singleSelect: false,
      preloader: false,
      items: [],
      headers: [],
      search: "",
      dialog: false,
      detailDialog: false,
      componentKey: 0,
      itemData: [],
      importDialog: false,
    };
  },

  created: function () {
    this.getData();
  },

  methods: {
    buttonText() {
      if (this.selected.length > 1) {
        return `${this.selected.length} Produkte importieren`;
      } else {
        return `Produkt importieren`;
      }
    },

    async importSelected() {
      const promises = this.selected.map((item) => {
        let formData = new FormData();
        formData.append("templateId", item.id);
        formData.append("companyId", this.companyId);
        return this.postRequest(
          `/addons/shop/${this.shopId}/products`,
          null,
          formData
        );
      });

      await Promise.all(promises);
      this.$emit("imported");
    },

    async getData() {
      this.preloader = true;
      let response = await this.getRequest(`/addons/shop/productsTemplates`);
      this.preloader = false;

      if (response) {
        this.headers = [
          { text: "ID", value: "id" },
          { text: "Titel", value: "title" },
          { text: "Preis", value: "priceGross" },
          { text: "MwSt.", value: "vatRate" },
          { text: "Aktiv", value: "isActive" },
        ];

        this.items = response.data.data;
      }
    },
  },

  mixins: [apiRequest],
};
</script>
