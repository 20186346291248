<script>
import ShopProductList from "@/components/addons/shops/ShopProductList.vue";
import ShopCategoryList from "@/components/addons/shops/ShopCategoryList.vue";
import ShopSettings from "@/components/addons/shops/ShopSettings.vue";
export default {
  name: "Shop",
  components: {
    ShopProductList,
    ShopCategoryList,
    ShopSettings,
  },
  props: ["itemData"],
  data() {
    return {
      shopId: this.itemData.id,
      companyId: this.itemData.companies_id,
      tab: null,

      items: [
        {
          id: 1,
          text: "Produkte",
          component: "ShopProductList",
        },
        {
          id: 2,
          text: "Kategorien",
          component: "ShopCategoryList",
        },
        {
          id: 3,
          text: "Einstellungen",
          component: "ShopSettings",
        },
      ],
    };
  },
};
</script>

<template>
  <div id="page">
    <v-tabs v-model="tab" align-with-title>
      <v-tabs-slider color="primary"></v-tabs-slider>
      <v-tab v-for="item in items" :key="item.id">
        {{ item.text }}
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item v-for="item in items" :key="item.id">
        <v-card flat>
          <v-card-text>
            <component
              :is="item.component"
              :shopId="shopId"
              :companyId="companyId"
              :data="itemData"
            />
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>
