<template>
  <div>
    <v-row>
      <v-col cols="12">
        <v-btn color="primary" class="ma-2" @click="importDialog = true">
          <v-icon>mdi-plus</v-icon>
          <span>Produkt importieren</span>
        </v-btn>
        <DataTable
          @tableAction="emittedAction"
          v-bind:items="items"
          v-bind:headers="headers"
          :itemActions="{
            edit: true,
            trash: true,
          }"
          :preloader="preloader"
        />
      </v-col>
    </v-row>

    <v-dialog
      v-model="importDialog"
      max-width="800px"
      transition="dialog-bottom-transition"
      scrollable
    >
      <v-card>
        <v-toolbar dark color="primary" elevation="0">
          <v-toolbar-title>Produkt importieren</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="importDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="mt-2">
          <ProductTemplatesList
            :shopId="shopId"
            :companyId="companyId"
            @imported="
              getData();
              importDialog = false;
            "
          />
        </v-card-text>
      </v-card>
    </v-dialog>

    <AreYouSureDialog
      title="Soll das Produkt gelöscht werden?"
      text="Wenn du das Produkt tatsächlich löschen möchtest, klicke auf 'Ja'. Ansonsten klicke auf 'Nein'."
      :dialog="dialog"
      @dialogEmit="dialogEmit"
    />

    <v-dialog
      v-model="detailDialog"
      max-width="800px"
      transition="dialog-bottom-transition"
      scrollable
      persistent
      :key="componentKey"
    >
      <v-card>
        <v-toolbar dark color="primary" elevation="0">
          <v-toolbar-title>Produkt</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="detailDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="mt-2">
          <Product
            :itemData="itemData"
            :shopId="shopId"
            :companyId="companyId"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import apiRequest from "@/mixins/apiRequest";
import DataTable from "@/components/ui/DataTable.vue";
import ProductTemplatesList from "@/components/addons/shops/ProductTemplatesList.vue";
import AreYouSureDialog from "@/components/ui/AreYouSureDialog.vue";
import Product from "@/views/tech/addons/shop/products/Product.vue";
export default {
  name: "ShopProductList",

  components: {
    DataTable,
    ProductTemplatesList,
    AreYouSureDialog,
    Product,
  },

  props: ["shopId", "companyId"],

  data() {
    return {
      preloader: false,
      items: [],
      headers: [],
      search: "",
      dialog: false,
      detailDialog: false,
      componentKey: 0,
      itemData: [],
      importDialog: false,
    };
  },

  created: function () {
    this.getData();
  },

  methods: {
    async emittedAction(value) {
      if (value.action === "deleteItem") {
        /* da wir einen dialog zwischenschalten, muss ich den index und die item id speichern, damit ich diese im Anschluss löschen kann.
        Das löse ich hier mit currentItemId und currentItemIndex */
        this.currentItemId = value.itemId;
        this.currentItemIndex = value.itemIndex;
        this.dialog = true; // starte den Dialog. Rückgabe erfolgt via emit nach dialogEmit()
      } else if (value.action === "editItem") {
        this.componentKey += 1;
        this.itemData = value.item;
        this.itemIndex = value.index;
        this.detailDialog = true;
      }
    },

    async getData() {
      this.preloader = true;
      let response = await this.getRequest(
        `/addons/shop/${this.shopId}/products`
      );
      this.preloader = false;

      if (response) {
        this.headers = [
          { text: "ID", value: "id" },
          { text: "Titel", value: "title" },
          { text: "Preis", value: "priceGross" },
          { text: "MwSt.", value: "vatRate" },
          { text: "Aktiv", value: "isActive" },
          { text: "Actions", value: "actions", sortable: false },
        ];

        this.items = response.data.data;
      }
    },

    async deleteItem() {
      /* DELETE /addons/shop/{shopId}/products/{id} */
      let response = await this.deleteRequest(
        `addons/shop/${this.shopId}/products/${this.currentItemId}`
      );
      if (response.status === 200) {
        this.items.splice(this.currentItemIndex, 1); // remove from array
      }
    },

    dialogEmit(value) {
      if (value) {
        /* ANSWER: TRUE */

        this.deleteItem();
        this.dialog = false;

        /* start snackbar */
        this.$root.snackbarGlobal.snackbar = true;
        this.$root.snackbarGlobal.snackbarText =
          "Der Datensatz wurde erfolgreich gelöscht.";
        this.$root.snackbarGlobal.snackbarColor = "green";
        this.$root.snackbarGlobal.snackbarTimeout = 5000;
      } else {
        /* ANSWER: FALSE */

        this.dialog = false;

        /* start snackbar */
        this.$root.snackbarGlobal.snackbar = true;
        this.$root.snackbarGlobal.snackbarText =
          "Der Datensatz wurde nicht gelöscht.";
        this.$root.snackbarGlobal.snackbarColor = "green";
        this.$root.snackbarGlobal.snackbarTimeout = 5000;
      }
    },
  },

  mixins: [apiRequest],
};
</script>
