<template>
  <div id="page">
    <PageHeader
      :pageTitle="pageTitle"
      :pageDescription="pageDescription"
    ></PageHeader>

    <v-row>
      <v-col cols="12">
        <!-- router links -->
        <v-btn
          color="primary"
          class="ma-2"
          :disabled="true"
          @click="$router.push('/tech/addons/shop/shops')"
        >
          <v-icon>mdi-cart</v-icon>
          <span>Kunden-Shops</span>
        </v-btn>

        <v-btn
          color="primary"
          class="ma-2"
          @click="$router.push('/tech/addons/shop/products')"
        >
          <v-icon>mdi-tag-heart</v-icon>
          <span>Produkte</span>
        </v-btn>
      </v-col>
      <v-col cols="12">
        <DataTable
          @tableAction="emittedAction"
          v-bind:items="items"
          v-bind:headers="headers"
          :itemActions="{
            edit: true,
          }"
          :preloader="preloader"
        />
      </v-col>
    </v-row>

    <AreYouSureDialog
      title="Soll der Bonus gelöscht werden?"
      text="Wenn du den Bonus tatsächlich löschen möchtest, klicke auf 'Ja'. Ansonsten klicke auf 'Nein'."
      :dialog="dialog"
      @dialogEmit="dialogEmit"
    />

    <v-dialog
      v-model="detailDialog"
      max-width="1200px"
      transition="dialog-bottom-transition"
      scrollable
      :key="componentKey"
    >
      <v-card>
        <v-toolbar dark color="primary" elevation="0">
          <v-toolbar-title>Shop</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="detailDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="mt-2">
          <Shop :itemData="itemData" />
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import apiRequest from "@/mixins/apiRequest";
import PageHeader from "@/components/ui/PageHeader.vue";
import DataTable from "@/components/ui/DataTable.vue";
import Shop from "@/views/tech/addons/shop/Shop.vue";
import AreYouSureDialog from "@/components/ui/AreYouSureDialog.vue";
export default {
  name: "ShopAddon",

  components: {
    PageHeader,
    DataTable,
    AreYouSureDialog,
    Shop,
  },

  data() {
    return {
      pageTitle: "Kunden-Shops",
      pageDescription: `<p>Hier werden die Kunden-Shops verwaltet</p>`,
      title: "",
      preloader: false,
      items: [],
      headers: [],
      edit: true,
      trash: false,
      preview: false,
      copy: false,
      search: "",
      dialog: false,
      detailDialog: false,
      componentKey: 0,
      itemData: [],
    };
  },

  created: function () {
    this.getData();
  },

  methods: {
    async deleteItem() {
      alert("Not possible yet");
    },

    dialogEmit(value) {
      if (value) {
        /* ANSWER: TRUE */

        this.deleteItem();
        this.dialog = false;

        /* start snackbar */
        this.$root.snackbarGlobal.snackbar = true;
        this.$root.snackbarGlobal.snackbarText =
          "Der Datensatz wurde erfolgreich gelöscht.";
        this.$root.snackbarGlobal.snackbarColor = "green";
        this.$root.snackbarGlobal.snackbarTimeout = 5000;
      } else {
        /* ANSWER: FALSE */

        this.dialog = false;

        /* start snackbar */
        this.$root.snackbarGlobal.snackbar = true;
        this.$root.snackbarGlobal.snackbarText =
          "Der Datensatz wurde nicht gelöscht.";
        this.$root.snackbarGlobal.snackbarColor = "green";
        this.$root.snackbarGlobal.snackbarTimeout = 5000;
      }
    },

    async createNew() {
      alert("Not possible yet");
    },

    async emittedAction(value) {
      if (value.action === "deleteItem") {
        /* da wir einen dialog zwischenschalten, muss ich den index und die item id speichern, damit ich diese im Anschluss löschen kann.
        Das löse ich hier mit currentItemId und currentItemIndex */
        this.currentItemId = value.itemId;
        this.currentItemIndex = value.itemIndex;
        this.dialog = true; // starte den Dialog. Rückgabe erfolgt via emit nach dialogEmit()
      } else if (value.action === "editItem") {
        this.componentKey += 1;
        this.itemData = value.item;
        this.itemIndex = value.index;
        this.detailDialog = true;
      }
    },

    async getData() {
      this.preloader = true;
      let response = await this.getRequest("/addons/shop");
      this.preloader = false;

      if (response) {
        this.headers = [
          { text: "ID", value: "id" },
          { text: "Kunde", value: "companies_name" },
          { text: "Titel", value: "title" },
          { text: "Actions", value: "actions", sortable: false },
        ];

        this.items = response.data.data;
      }
    },
  },

  mixins: [apiRequest],
};
</script>
