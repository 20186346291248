<template>
  <div>
    <v-row>
      <v-col cols="12">
        <v-btn color="primary" class="ma-2" @click="createCategory()">
          <v-icon>mdi-plus</v-icon>
          <span>Kategorie erstellen</span>
        </v-btn>

        <DataTable
          @tableAction="emittedAction"
          v-bind:items="items"
          v-bind:headers="headers"
          :itemActions="{
            edit: true,
          }"
          :preloader="preloader"
        />
      </v-col>
    </v-row>

    <v-dialog
      v-model="editDialog"
      max-width="1200px"
      transition="dialog-bottom-transition"
      scrollable
    >
      <v-card>
        <v-toolbar dark color="primary" elevation="0">
          <v-toolbar-title>Kategorie editieren</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="editDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="mt-2">
          <ShopEditCategory
            :itemData="itemData"
            :shopId="shopId"
            :key="componentKey"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import apiRequest from "@/mixins/apiRequest";
import DataTable from "@/components/ui/DataTable.vue";
import ShopEditCategory from "@/components/addons/shops/ShopEditCategory.vue";
export default {
  name: "ShopCategoryList",

  components: {
    DataTable,
    ShopEditCategory,
  },

  props: ["shopId"],

  data() {
    return {
      preloader: false,
      items: [],
      headers: [],
      search: "",
      dialog: false,
      detailDialog: false,
      componentKey: 0,
      itemData: [],
      editDialog: false,
    };
  },

  created: function () {
    this.getData();
  },

  methods: {
    async emittedAction(value) {
      if (value.action === "deleteItem") {
        /* da wir einen dialog zwischenschalten, muss ich den index und die item id speichern, damit ich diese im Anschluss löschen kann.
        Das löse ich hier mit currentItemId und currentItemIndex */
        this.currentItemId = value.itemId;
        this.currentItemIndex = value.itemIndex;
        this.dialog = true; // starte den Dialog. Rückgabe erfolgt via emit nach dialogEmit()
      } else if (value.action === "editItem") {
        this.componentKey += 1;
        this.itemData = value.item;
        this.itemIndex = value.index;
        this.editDialog = true;
      }
    },

    async createCategory() {
      /* create new entry */
      let response = await this.postRequest(
        `/addons/shop/${this.shopId}/categories`
      ); // await data from mixin

      let newItem = response.data.data;
      this.items.splice(0, 0, newItem); // add to array at index 0

      /* start snackbar */
      this.$root.snackbarGlobal.snackbar = true;
      this.$root.snackbarGlobal.snackbarText =
        "Eine neue Kategorie wurde erfolgreich angelegt.";
      this.$root.snackbarGlobal.snackbarColor = "green";
      this.$root.snackbarGlobal.snackbarTimeout = 5000;
    },

    async getData() {
      this.preloader = true;
      let response = await this.getRequest(
        `/addons/shop/${this.shopId}/categories`
      );
      this.preloader = false;

      if (response) {
        this.headers = [
          { text: "ID", value: "id" },
          { text: "Titel", value: "title" },
          { text: "Beschreibung", value: "description" },
          { text: "Actions", value: "actions", sortable: false },
        ];

        this.items = response.data.data;
      }
    },
  },

  mixins: [apiRequest],
};
</script>
