<template>
  <v-container>
    <Patcher
      :itemValue="data.title"
      itemName="title"
      itemLabel="Name"
      :hideLabel="false"
      :route="patchRoute"
      inputElement="input"
      inputType="text"
      selectOptions=""
      itemValueKey=""
      itemTextKey=""
      :previewValue="data.title"
      @emit="data.title = $event.value"
    ></Patcher>
    <Patcher
      :itemValue="data.paymentProviders"
      itemName="paymentProviders"
      itemLabel="Payment Provider"
      :hideLabel="false"
      :route="patchRoute"
      inputElement="multiselect"
      inputType=""
      :selectOptions="[
        { name: 'sepa', text: 'SEPA' },
        { name: 'paypal', text: 'PayPal' },
      ]"
      itemValueKey="name"
      itemTextKey="name"
      :previewValue="data.paymentProviders"
      @emit="data.paymentProviders = $event.value"
    ></Patcher>
  </v-container>
</template>

<script>
import Patcher from "@/components/ui/Patcher.vue";
import apiRequest from "@/mixins/apiRequest";
import helpers from "@/mixins/helpers";
export default {
  name: "ShopSettings",
  components: {
    Patcher,
  },

  props: ["shopId", "companyId", "data"],

  data() {
    return {
      filePatcherKey: 0,
      patchRoute: `/addons/shop/${this.shopId}`,
      fetchingData: false,
    };
  },

  mixins: [apiRequest, helpers],
};
</script>
