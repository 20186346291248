<template>
  <v-container>
    <Patcher
      :itemValue="itemData.isActive"
      itemName="isActive"
      itemLabel="Aktiv"
      :hideLabel="true"
      :route="patchRoute"
      inputElement="switch"
      inputType=""
      @emit="itemData.isActive = $event.value"
    ></Patcher>

    <Patcher
      :itemValue="itemData.mediaUuid"
      itemName="mediaUuid"
      itemLabel="Foto"
      :hideLabel="false"
      :route="patchRoute"
      inputElement=""
      inputType="media"
      :previewValue="itemData.mediaUuid"
      @emit="itemData.mediaUuid = $event.value"
    ></Patcher>

    <Patcher
      :itemValue="itemData.title"
      itemName="title"
      itemLabel="Name"
      :hideLabel="false"
      :route="patchRoute"
      inputElement="input"
      inputType="text"
      selectOptions=""
      itemValueKey=""
      itemTextKey=""
      :previewValue="itemData.title"
      @emit="itemData.title = $event.value"
    ></Patcher>

    <Patcher
      :itemValue="itemData.descriptionShort"
      itemName="descriptionShort"
      itemLabel="Kurzbeschreibung"
      :hideLabel="false"
      :route="patchRoute"
      inputElement="textarea"
      inputType=""
      selectOptions=""
      itemValueKey=""
      itemTextKey=""
      :previewValue="itemData.descriptionShort"
      @emit="itemData.descriptionShort = $event.value"
    ></Patcher>

    <Patcher
      :itemValue="itemData.description"
      itemName="description"
      itemLabel="Beschreibung"
      :hideLabel="false"
      :route="patchRoute"
      inputElement="textarea-md"
      inputType=""
      selectOptions=""
      itemValueKey=""
      itemTextKey=""
      :previewValue="itemData.description"
      @emit="itemData.description = $event.value"
    ></Patcher>

    <Patcher
      :itemValue="itemData.features"
      itemName="features"
      itemLabel="Features"
      :hideLabel="false"
      :route="patchRoute"
      inputElement="textarea"
      inputType=""
      selectOptions=""
      itemValueKey=""
      itemTextKey=""
      :previewValue="itemData.features"
      @emit="itemData.features = $event.value"
    ></Patcher>

    <Patcher
      :itemValue="itemData.priceGross"
      itemName="priceGross"
      itemLabel="Preis brutto"
      :hideLabel="false"
      :route="patchRoute"
      inputElement="input"
      inputType="number"
      selectOptions=""
      itemValueKey=""
      itemTextKey=""
      :previewValue="itemData.priceGross"
      @emit="itemData.priceGross = $event.value"
    ></Patcher>

    <Patcher
      :itemValue="itemData.vatRate"
      itemName="vatRate"
      itemLabel="Steuersatz"
      :hideLabel="false"
      :route="patchRoute"
      inputElement="input"
      inputType="number"
      selectOptions=""
      itemValueKey=""
      itemTextKey=""
      :previewValue="itemData.vatRate"
      @emit="itemData.vatRate = $event.value"
    ></Patcher>

    <!-- <Patcher
      :itemValue="itemData.hasFee"
      itemName="hasFee"
      itemLabel="Produkt besitzt Zusatzkosten"
      :hideLabel="true"
      :route="patchRoute"
      inputElement="switch"
      inputType=""
      selectOptions=""
      itemValueKey=""
      itemTextKey=""
      :previewValue="itemData.hasFee"
      @emit="itemData.hasFee = $event.value"
    ></Patcher> -->

    <Patcher
      :itemValue="itemData.fineprint"
      itemName="fineprint"
      itemLabel="Das Kleingedruckte"
      :hideLabel="false"
      :route="patchRoute"
      inputElement="textarea"
      inputType=""
      selectOptions=""
      itemValueKey=""
      itemTextKey=""
      :previewValue="itemData.fineprint"
      @emit="itemData.fineprint = $event.value"
    ></Patcher>

    <!-- <template v-if="itemData.hasFee === '1'">
      <Patcher
        :itemValue="itemData.feeTitle"
        itemName="feeTitle"
        itemLabel="Zusatzkosten-Titel"
        :hideLabel="false"
        :route="patchRoute"
        inputElement="input"
        inputType="text"
        selectOptions=""
        itemValueKey=""
        itemTextKey=""
        :previewValue="itemData.feeTitle"
        @emit="itemData.feeTitle = $event.value"
      ></Patcher>

      <Patcher
        :itemValue="itemData.feeDescription"
        itemName="feeDescription"
        itemLabel="Zusatzkosten-Beschreibung"
        :hideLabel="false"
        :route="patchRoute"
        inputElement="textarea-md"
        inputType=""
        selectOptions=""
        itemValueKey=""
        itemTextKey=""
        :previewValue="itemData.feeDescription"
        @emit="itemData.feeDescription = $event.value"
      ></Patcher>

      <Patcher
        :itemValue="itemData.feePriceGross"
        itemName="feePriceGross"
        itemLabel="Zusatzkosten-Preis brutto"
        :hideLabel="false"
        :route="patchRoute"
        inputElement="input"
        inputType="number"
        selectOptions=""
        itemValueKey=""
        itemTextKey=""
        :previewValue="itemData.feePriceGross"
        @emit="itemData.feePriceGross = $event.value"
      ></Patcher>
    </template> -->

    <Patcher
      :itemValue="itemData.confirmationAdditiveHtml"
      itemName="confirmationAdditiveHtml"
      itemLabel="Produkt-Zusatz in der Bestätigungsmail/Bestellbestätigung"
      :hideLabel="false"
      :route="patchRoute"
      inputElement="textarea-md"
      inputType=""
      selectOptions=""
      itemValueKey=""
      itemTextKey=""
      :previewValue="itemData.confirmationAdditiveHtml"
      @emit="itemData.confirmationAdditiveHtml = $event.value"
    ></Patcher>
  </v-container>
</template>

<script>
import Patcher from "@/components/ui/Patcher.vue";
import apiRequest from "@/mixins/apiRequest";
import helpers from "@/mixins/helpers";
export default {
  name: "Product",
  components: {
    Patcher,
  },

  props: ["itemData", "shopId", "companyId"],

  data() {
    return {
      filePatcherKey: 0,
      patchRoute:
        this.shopId !== ""
          ? `/addons/shop/${this.shopId}/products/${this.itemData.id}`
          : `/addons/shop/productsTemplates/${this.itemData.id}`,
      fetchingData: false,
    };
  },

  mixins: [apiRequest, helpers],
};
</script>
